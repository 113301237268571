import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Link from "gatsby-plugin-transition-link";

import ButtonWithUnderline from "../Button/ButtonWithUnderline";

const ValueProps = ({ className, headingLevel, heading }) => {
  const data = useStaticQuery(graphql`
    {
      firstTime: file(
        relativePath: { eq: "repeating/specialties/1.0  First time .jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 576)
        }
      }
      secondHome: file(
        relativePath: { eq: "repeating/specialties/1.1 Second Home.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 576)
        }
      }
      moveup: file(
        relativePath: { eq: "repeating/specialties/2.0 Move up .jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 576)
        }
      }
      investing: file(
        relativePath: { eq: "repeating/specialties/2.1 Investing.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 576)
        }
      }
    }
  `);

  const content = [
    {
      image: data.firstTime.childImageSharp.gatsbyImageData,
      heading: "First-Time Home Buyers",
      text: "Overwhelmed? Lean on our decades of experience to walk you through the process, step by step.",
      link: "/first-time-home-loans-minnesota/",
    },
    {
      image: data.secondHome.childImageSharp.gatsbyImageData,
      heading: "Second Home Buyer",
      text: "Are you interested in purchasing a vacation home, lake property or cabin? Let us know!",
      link: "/second-home-buyer/",
    },
    {
      image: data.moveup.childImageSharp.gatsbyImageData,
      heading: "Move-Up Buyers",
      text: "Considering upgrading to a larger, or more expensive home? We’ll walk you through the loan options.",
      link: "/move-up-home-buyers/",
    },
    {
      image: data.investing.childImageSharp.gatsbyImageData,
      heading: "Investors",
      text: "Talk to us about opportunities and strategies on how to finance and own residential investment properties.",
      link: "/mortgage-broker-investors/",
    },
  ];

  const HeadingTag = headingLevel || "h2";

  return (
    <section className={`${className || "mb-20 md:mb-32"}`}>
      <div className="container">
        <header className="text-center mb-10">
          <HeadingTag>{heading || "Our Specialties"}</HeadingTag>
        </header>

        <div className="grid md:grid-cols-2 gap-y-6 md:gap-y-8 md:gap-x-8">
          {content.map((content, i) => {
            return (
              <>
                <Link
                  to={content.link}
                  key={i}
                  className="group pt-3 px-3 pb-6 md:p-3 shadow-3xl"
                >
                  <div className="overflow-hidden mb-6">
                    <GatsbyImage image={content.image} alt={content.heading} />
                  </div>
                  <div className="md:col-end-13 md:col-span-8">
                    <h3 className="heading-four mb-2">{content.heading}</h3>
                    <p className="mb-3">{content.text}</p>
                    <ButtonWithUnderline
                      href={content.link}
                      text="Learn More"
                    />
                  </div>
                </Link>
              </>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default ValueProps;
