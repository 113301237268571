import React, { useState } from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import Hero from "../components/Hero/HeroFullWidth";
import Specialties from "../components/Repeating/Specialties";
import Testimonials from "../components/Repeating/Testimonials";
import ValueProps from "../components/Repeating/ValueProps";
import About from "../components/Repeating/About";
import Resources from "../components/Repeating/Resources";
import CallToAction from "../components/Repeating/CTA";
import ButtonSolid from "../components/Button/ButtonSolid";
import ButtonGhost from "../components/Button/ButtonGhost";
import ButtonVerticalLines from "../components/Button/ButtonVerticalLines";

const Page = ({ data }) => {
  const heroImages = [
    getImage(data.heroDesktop.childImageSharp.gatsbyImageData),
    {
      ...getImage(data.heroMobile.childImageSharp.gatsbyImageData),
      media: `(max-width: 767px)`,
    },
  ];

  const [setPurchase, setPurchaseState] = useState("block");
  const [setRefinance, setRefinanceState] = useState("hidden");

  const purchaseClickHandler = () => {
    setPurchaseState("block");
    setRefinanceState("hidden");
  };

  const refinanceClickHandler = () => {
    setPurchaseState("hidden");
    setRefinanceState("block");
  };

  return (
    <Layout>
      <SearchEngineOptimization
        title="Eden Prairie Mortgage Broker | MN | Thistle Financial"
        description="Thistle Financial is a full-service Minnesota mortgage broker committed to local borrowers in Eden Prairie and the surrounding Twin Cities."
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <Hero
        backgroundImages={heroImages}
        padding="pt-80 md:pt-44 pb-7 md:pb-52"
      >
        <header className="max-w-2xl text-center md:text-left">
          <p className="font-heading text-white text-mobile-7xl md:text-7xl font-medium tracking-wider mb-3 md:mb-4">
            Dream It. Own It.
          </p>
          <p className="font-heading text-xl md:text-2xl tracking-wide text-gray-50">
            The best rates, clear terms, and a broker in your corner. Your home
            buying experience just got easier.
          </p>
          <div className="flex items-center justify-center md:justify-start space-x-5">
            <ButtonSolid href="/buying-home-minnesota/" text="Purchase" />
            <ButtonGhost href="/refinance-minnesota/" text="Refinance" />
          </div>
        </header>
      </Hero>

      <section className="mb-8 md:mb-32 pt-16">
        <div className="container">
          <div className="grid md:grid-cols-2 gap-y-8 md:gap-x-10 lg:gap-x-20 items-center">
            <div className="order-2 md:order-1">
              <div className="hidden md:block">
                <GatsbyImage
                  image={data.badge.childImageSharp.gatsbyImageData}
                  className="mb-6"
                />
              </div>
              <h1>Eden Prairie Mortgage Broker Serving The Twin Cities</h1>
              <p>
                Thistle Financial, headed by founder/broker Mark Thistle, is a
                full-service Minnesota mortgage company committed to local
                borrowers in Eden Prairie and the surrounding Twin Cities area.
                Whether you're dreaming of buying a new or second home, looking
                to refinance, or are an investor interested in purchasing a
                multi-unit property like a duplex, we can make it happen.
              </p>
              <div className="flex justify-center md:justify-start">
                <ButtonVerticalLines href="/about-us/" text="Learn More" />
              </div>
            </div>
            <div className="md:m-auto order-1 md:order-2">
              <div className="relative inline-flex">
                <div className="md:hidden absolute top-0 right-0 z-10">
                  <GatsbyImage
                    image={data.badge.childImageSharp.gatsbyImageData}
                  />
                </div>
                <GatsbyImage
                  image={data.intro.childImageSharp.gatsbyImageData}
                  className="shadow-3xl"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mb-20 md:mb-32 pt-16 bg-primary-50 py-20 md:py-32">
        <div className="container">
          <div className="grid md:grid-cols-2 gap-y-8 md:gap-x-10 lg:gap-x-20">
            <div>
              <div className={setPurchase}>
                <GatsbyImage
                  image={data.purchase.childImageSharp.gatsbyImageData}
                />
              </div>
              <div className={setRefinance}>
                <GatsbyImage
                  image={data.refinance.childImageSharp.gatsbyImageData}
                />
              </div>
            </div>
            <div className="md:mt-10 lg:mt-28">
              <div className="flex justify-center md:justify-start">
                <div className="inline-flex border border-primary-400 mb-8 md:mb-10">
                  <button
                    className={`text-sm uppercase tracking-wider min-w-[156px] h-[46px] flex justify-center items-center transition-colors duration-300 ease-linear ${
                      setPurchase === "block"
                        ? "bg-primary-400 text-white"
                        : "text-primary-400"
                    }`}
                    onKeyDown={purchaseClickHandler}
                    onClick={purchaseClickHandler}
                  >
                    Buy a home
                  </button>
                  <button
                    className={`text-sm uppercase tracking-wider min-w-[156px] h-[46px] flex justify-center items-center transition-colors duration-300 ease-linear ${
                      setRefinance === "block"
                        ? "bg-primary-400 text-white"
                        : "text-primary-400"
                    }`}
                    onKeyDown={refinanceClickHandler}
                    onClick={refinanceClickHandler}
                  >
                    Refinance
                  </button>
                </div>
              </div>

              <div className={setPurchase}>
                <h2>I Want To Buy A Home</h2>
                <p>
                  Are you a first-time home buyer? Or perhaps you’re interested
                  in purchasing a second home? We’re here to help match you with
                  the right loan and get the best mortgage rate.
                </p>
                <div className="flex justify-center md:justify-start">
                  <ButtonVerticalLines
                    href="/buying-home-minnesota/"
                    text="Tell Me More"
                  />
                </div>
              </div>
              <div className={setRefinance}>
                <h2>I Want To Refinance</h2>
                <p>
                  Are you thinking of refinancing? We offer a wide range of
                  options, whether you're looking for some extra cash or want a
                  better rate and term.
                </p>
                <div className="flex justify-center md:justify-start">
                  <ButtonVerticalLines
                    href="/refinance-minnesota/"
                    text="Tell Me More"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Specialties />
      <Testimonials />
      <ValueProps />
      <About />
      <Resources />
      <CallToAction />
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "open-graph/facebook/Homepage.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/Homepage.jpg" }
    ) {
      publicURL
    }
    heroDesktop: file(relativePath: { eq: "home/1.0 Hero Home.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
      }
    }
    heroMobile: file(relativePath: { eq: "home/1.0 Hero home mobile.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
      }
    }
    intro: file(relativePath: { eq: "home/2.1 intro.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 436)
      }
    }
    badge: file(relativePath: { eq: "home/2.0 Badge.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 101)
      }
    }
    purchase: file(relativePath: { eq: "home/3.A Buy home.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 560)
      }
    }
    refinance: file(relativePath: { eq: "home/3.B Refinance.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 560)
      }
    }
  }
`;
export default Page;
